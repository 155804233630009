@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
}

.ant-select-selector{
    background-color: #FAB414 !important;
    border: none !important;
    color: white !important;
    outline: none !important;
    border-radius: 6px !important;
}

.ant-select-selection-item, .ant-select-arrow{
    color: white !important;
}
.ant-select-selector .ant-select-selection-placeholder{
    color: white !important;
}

.ant-image-preview-img-wrapper{
    position: absolute;
    margin: 0 !important;
    top: 50% !important;
    left: 50% !important;
    right: unset !important;
    bottom: unset !important;
    -ms-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
}

.ant-table-tbody>tr>td, .ant-table-thead>tr>th, .ant-table tfoot>tr>td, .ant-table tfoot>tr>th{
    padding: 10px !important;
}

.drag-list {
    user-select: none;
    display: contents;
    justify-content: flex-start;
}
  
.drag-item {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
}

.drag-dragged {
    /* background-color: rgb(37, 37, 197); */
}
  
